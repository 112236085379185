import { useFrame, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import React, { useEffect, useRef, useState } from 'react';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils';
import { AnimationMixer, Euler, MathUtils, Quaternion, Vector3 } from 'three';
import { usePlayerStore } from 'services/PlayerService';
import { DEFAULT_FADE_DURATION, DEFAULT_TELEPORT_DELAY } from './ConfigAnimations';
import { useReactionStore } from 'services/ReactionService';
import dracoLoader from 'utilities/dracoLoader';
import { updateConfiguration } from './Configuration';
import { updateConfigurationGen3 } from './ConfigurationGen3';
import { useEnvironmentStore } from '../Environment/store';
import { useEventStore } from 'services/EventService';
import fromCdn from 'utilities/cdn';
import { useFrameAfterMount } from '../../../utilities/hooks';

const quaternion = new Quaternion();
const euler = new Euler();

function AnimationController({ isSelf, reactionId, appearance, getPlayerData, group }) {
  const avatar = useEventStore(state => state.event.avatar);
  const environmentConfiguration = useEnvironmentStore(state => state.environmentConfiguration);

  const gltf = useLoader(
    GLTFLoader,
    fromCdn(avatar?.glb || 'https://assets.virtual-experience.demodern.com/events/default/avatar/v1/Character_v01.glb'),
    dracoLoader
  );

  const [gltfData, setGltfData] = useState(null);
  useEffect(() => {
    const { animations } = gltf;
    gltf.scene.visible = true;
    const clonedScene = clone(gltf.scene);
    setGltfData({ animations, clonedScene });
  }, []);

  const wasRendered = useRef(false);
  const [mixer] = useState(() => new AnimationMixer());
  const reactions = useRef({});
  const [playerVisible, setPlayerVisible] = useState(reactionId !== -1);
  const isTeleport = useReactionStore.getState().isTeleport(reactionId);

  const motions = useRef();
  const motionStrengths = useRef({
    Idle: 0.0,
    Walk: 0.0,
    Run: 0.0,
    WalkBackward: 0.0,
    Turn: 0.0,
  });
  const lifetime = useRef(0);

  const activeReaction = useRef();

  useEffect(() => {
    if (!gltfData) return;

    const { animations, clonedScene } = gltfData;

    motions.current = {
      Idle: mixer.clipAction(animations[0], group.current),
      Walk: mixer.clipAction(animations[1], group.current),
      WalkBackward: mixer.clipAction(animations[3], group.current),
      Run: mixer.clipAction(animations[2], group.current),
      Turn: mixer.clipAction(animations[4], group.current),
    };

    useReactionStore.getState().reactions.forEach(r => {
      if (r.animation) {
        reactions.current[r.id] = mixer.clipAction(animations[r.animation.glbAnimationIndex], group.current);
        reactions.current[r.id].setLoop(r.animation.loop, r.animation.repetitions || 0);
        reactions.current[r.id].clampWhenFinished = true;
      }
    });

    activeReaction.current = reactions.current[reactionId] || null;

    const onFinish = () => {
      usePlayerStore.getState().playerActions.setReaction(0);
    };

    clonedScene.traverse(object => {
      if (object.isMesh) {
        object.frustumCulled = !isSelf;
        object.castShadow = true;
        object.receiveShadow = true;
        if (object.material) {
          object.material.envMapIntensity = environmentConfiguration.envMap.intensity;
        }
        object.onAfterRender = () => {
          wasRendered.current = true;
        };
      }
    });

    if (isSelf) {
      mixer.addEventListener('finished', onFinish);
      return () => {
        mixer.removeEventListener('finished', onFinish);
      };
    }
  }, [environmentConfiguration.envMap.intensity, gltfData]);

  useEffect(() => {
    if (!gltfData) return;

    const { clonedScene } = gltfData;
    if (useEventStore.getState().event.avatar.gen === 3) {
      updateConfigurationGen3(clonedScene, appearance);
    } else {
      updateConfiguration(clonedScene, appearance);
    }
  }, [appearance, gltfData]);

  useEffect(() => {
    if (isTeleport) {
      lifetime.current = 0;
      setPlayerVisible(false);
    }
    if (activeReaction.current) {
      activeReaction.current.fadeOut(DEFAULT_FADE_DURATION);
    }
    activeReaction.current = reactions.current[reactionId] || null;

    if (activeReaction.current) {
      activeReaction.current
        .reset()
        .setEffectiveTimeScale(1)
        .setEffectiveWeight(1)
        .fadeIn(DEFAULT_FADE_DURATION)
        .play();
    }
  }, [reactionId]);

  useFrameAfterMount((state, delta) => {
    if (!group.current) return;

    if (reactionId !== -1) {
      lifetime.current += delta;
    }

    if (!playerVisible) {
      if (lifetime.current > DEFAULT_TELEPORT_DELAY) {
        setPlayerVisible(true);
      }
    }

    const strengths = {
      Idle: reactionId ? 0.0 : 1.0,
      Walk: 0.0,
      Run: 0.0,
      WalkBackward: 0.0,
      Turn: 0.0,
    };

    const playerData = getPlayerData(delta);
    if (!playerData) {
      // eslint-disable-next-line no-console
      console.warn('playerData returned null');
      return null;
    }

    if (group.current && playerData) {
      if (playerData.position) {
        group.current.position.set(playerData.position[0], playerData.position[1], playerData.position[2]);
      }

      if (playerData.rotation) {
        quaternion.setFromEuler(euler.set(0, playerData.rotation, 0));
        group.current.quaternion.slerp(quaternion, 1.0 - Math.pow(0.00002, delta));
      }

      if (playerData.velocity) {
        const rotation = playerData.rotation || 0;
        const delta = new Vector3(playerData.velocity[0], playerData.velocity[1], playerData.velocity[2]);
        const length = delta.length();

        const EPSILON = 0.001;
        const RUN_THRESHHOLD = 0.01;
        const IDLE_THRESHHOLD = 0.005;
        const MAX_SPEED = 0.02;
        const MAX_SPEED_BACKWARD = 0.006;
        if (length > EPSILON) {
          delta.applyEuler(new Euler(0, -rotation, 0));
          strengths.Idle = MathUtils.clamp(1.0 - length / IDLE_THRESHHOLD, 0, 1);
          if (delta.z > 0) {
            if (length < RUN_THRESHHOLD) {
              strengths.Walk = length / RUN_THRESHHOLD;
            } else {
              strengths.Run =
                0.5 + 0.5 * MathUtils.clamp((length - RUN_THRESHHOLD) / (MAX_SPEED - RUN_THRESHHOLD), 0, 1);
            }
          } else {
            strengths.WalkBackward = Math.min(length / MAX_SPEED_BACKWARD, 1.0);
          }
        } else if (Math.abs(playerData.velocity[3]) > EPSILON) {
          strengths.Turn = MathUtils.clamp(Math.abs(playerData.velocity[3]) / 0.08, 0, 1);
        }
      }
    }

    Object.keys(strengths).forEach(key => {
      const EPSILON = 0.005;
      const prevStrength = motionStrengths.current[key];
      const newStrength = MathUtils.lerp(motionStrengths.current[key], strengths[key], 1.0 - Math.pow(0.001, delta));

      if (!motions.current) return;

      if (prevStrength > EPSILON && newStrength <= EPSILON) {
        motions.current[key].enabled = false;
      } else if (prevStrength <= EPSILON && newStrength > 0) {
        motions.current[key].enabled = true;
        motions.current[key].play();
      }

      motionStrengths.current[key] = newStrength;
      motions.current[key].setEffectiveWeight(newStrength);
    });

    if (wasRendered.current) {
      wasRendered.current = false;
      mixer.update(delta);
    }
  }, 1000);

  if (!gltfData) return null;

  return (
    <primitive
      name="AnimationController"
      object={gltfData.clonedScene}
      scale={playerVisible ? [1, 1, 1] : [0.001, 0.001, 0.001]}
      position={playerVisible ? [0, 0, 0] : [0, 1.5, 0]}
    />
  );
}

export default AnimationController;
