import React, { useEffect, useState } from 'react';
import Character from '../../Play/Character';
import { useFrameAfterMount } from '../../../utilities/hooks';
import { useAvatarStore } from '../../../services/AvatarService';
import { useUserStore } from '../../../services/UserService';

function makeCharacter(radians) {
  useAvatarStore.getState().initConfigurationFromAppearance(null);
  const appearance = useAvatarStore.getState().getAppearance();
  useAvatarStore.getState().initConfigurationFromAppearance(useUserStore.getState().player.user.appearance);
  return {
    rnd1: Math.random(),
    rnd2: Math.random(),
    radians,
    appearance,
    playerData: {
      reaction: 10,
      position: [-6 + Math.sin(radians) * 10, -1, -20 + Math.cos(radians) * 10],
      rotation: 0,
      velocity: [0, 0, 0, 0],
    },
  };
}

export default function AvatarPerformance() {
  const [characters, setCharacters] = useState([]);

  useEffect(() => {
    const amount = 75;
    const result = [];
    for (let i = 0; i < amount; i++) {
      result.push(makeCharacter((i / amount) * Math.PI * 2));
    }
    setCharacters(result);
  }, []);

  useFrameAfterMount(context => {
    const t = context.clock.elapsedTime;
    characters.forEach(character => {
      const speed = character.rnd2 > 0.7 ? -0.2 : 0.5;
      const radius = 4 + speed * 4 + character.rnd1 * 2;
      const radians = character.radians + t * speed;
      const { playerData } = character;
      playerData.position[0] = -6 + Math.sin(radians) * radius;
      playerData.position[2] = -20 + Math.cos(radians) * radius;
      playerData.rotation = 1.5 + radians;
      playerData.velocity[0] = Math.sin(radians) * speed;
      playerData.velocity[2] = Math.cos(radians) * speed;
    });
  });

  return (
    <group name="Debug Avatars">
      {characters.map((character, i) => (
        <Character
          key={i}
          name={'Character-Preload'}
          visible={true}
          frustumCulled={true}
          playerData={character.playerData}
          reactionId={0}
          isSelf={false}
          indicatorVisible={false}
          appearance={character.appearance}
        />
      ))}
    </group>
  );
}
