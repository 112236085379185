import IconShoes from './icons/category/icon_shoes.png';
import IconSkin from './icons/category/icon_skin.png';
import IconTop from './icons/category/icon_top.png';
import IconHair from './icons/category/icon_hair.png';
import IconGlasses from './icons/category/icon_glasses.png';
import Thumb_Glasses_00 from './icons/options/glasses/Thumb_Glasses_00.png';
import female_open_long from './icons/options_gen3/hair/female_open_long.png';
import female_open_short from './icons/options_gen3/hair/female_open_short.png';
import female_ponytail from './icons/options_gen3/hair/female_ponytail.png';
import male_afro from './icons/options_gen3/hair/male_afro.png';
import male_combed_backwards from './icons/options_gen3/hair/male_combed_backwards.png';
import male_short_thick from './icons/options_gen3/hair/male_short_thick.png';
import male_short_thin from './icons/options_gen3/hair/male_short_thin.png';

import aviator_glasses from './icons/options_gen3/glasses/aviator_glasses.jpg';
import mc_glasses from './icons/options_gen3/glasses/mc_glasses.png';
import round_glasses from './icons/options_gen3/glasses/round_glasses.png';
import vr_glasses from './icons/options_gen3/glasses/vr_glasses.png';
import wayfarerglasses from './icons/options_gen3/glasses/wayfarerglasses.png';

import outfit_airjordan from './icons/options_gen3/outfit/outfit_airjordan.png';
import outfit_fullsuit from './icons/options_gen3/outfit/outfit_fullsuit.png';
import outfit_teckwear from './icons/options_gen3/outfit/outfit_teckwear.png';
import outfit_thickjacket from './icons/options_gen3/outfit/outfit_thickjacket.jpg';
import outfit_trousersshirt from './icons/options_gen3/outfit/outfit_trousersshirt.png';
import outfit_youngstylish from './icons/options_gen3/outfit/outfit_youngstylish.png';

import shoes_balenciaga from './icons/options_gen3/shoes/shoes_balenciaga.png';
import shoes_chelseaboots from './icons/options_gen3/shoes/shoes_chelseaboots.png';
import shoes_sneaker from './icons/options_gen3/shoes/shoes_sneaker.jpg';

import { character, characterGen3 } from './colors';
import { useTranslationStore } from 'services/TranslationService';

export const getInitialCustomizationConfigurationGen3 = () => {
  const translate = useTranslationStore.getState().translate;
  return [
    {
      id: 'hair',
      name: translate('avatar.tab.hair') || 'Hairstyle',
      selected: false,
      customized: false,
      icon: IconHair,
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: Thumb_Glasses_00, name: 'hair_NONE' },
            { icon: female_open_long, name: 'hair_female_open_long' },
            { icon: female_open_short, name: 'hair_female_open_short' },
            { icon: female_ponytail, name: 'hair_female_ponytail' },
            { icon: male_afro, name: 'hair_male_afro' },
            { icon: male_combed_backwards, name: 'hair_male_combed_backwards' },
            { icon: male_short_thick, name: 'hair_male_short_thick' },
            { icon: male_short_thin, name: 'hair_male_short_thin' },
          ],
          selections: [{ name: 'default', index: 1 }],
        },
        {
          type: 'color',
          options: [
            character.hair['1'],
            character.hair['2'],
            character.hair['3'],
            character.hair['4'],
            character.hair['5'],
            character.hair['6'],
            character.hair['7'],
            character.hair['8'],
            character.hair['9'],
            character.hair['10'],
            character.hair['11'],
            character.hair['12'],
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'facial_features',
      name: translate('avatar.tab.glasses') || 'Glasses',
      selected: false,
      customized: false,
      icon: IconGlasses,
      optionPanels: [
        {
          type: 'style',
          options: [
            // { icon: Thumb_Glasses_00, name: 'accesoires_NONE' },
            { icon: aviator_glasses, name: 'accesoires_aviatorglasses_mdl' },
            { icon: mc_glasses, name: 'accesoires_mcglasses_mdl' },
            { icon: round_glasses, name: 'accesoires_roundglasses_mdl' },
            { icon: vr_glasses, name: 'accesoires_vrglasses_mdl' },
            { icon: wayfarerglasses, name: 'accesoires_wayfarerglasses_mdl' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'skin',
      name: translate('avatar.tab.skin') || 'Skin color',
      selected: false,
      customized: false,
      icon: IconSkin,
      optionPanels: [
        {
          type: 'material',
          options: Object.values(characterGen3.skin),
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'outfit',
      name: translate('avatar.tab.outfit') || 'Outfit',
      selected: false,
      customized: false,
      icon: IconTop,
      camera: {
        position: null,
        target: { x: 0, y: 0.75, z: 0 },
      },
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: outfit_airjordan, name: 'outfit_airjordan_mdl' },
            { icon: outfit_fullsuit, name: 'outfit_fullsuit_mdl' },
            { icon: outfit_teckwear, name: 'outfit_techwear_mdl' },
            { icon: outfit_thickjacket, name: 'outfit_thickjacket_mdl' },
            { icon: outfit_trousersshirt, name: 'outfit_trousersshirt_mdl' },
            { icon: outfit_youngstylish, name: 'outfit_youngstylish_mdl' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },

    {
      id: 'shoes',
      name: translate('avatar.tab.shoes') || 'Shoes',
      selected: false,
      customized: false,
      icon: IconShoes,
      optionPanels: [
        {
          type: 'style',
          options: [
            { icon: shoes_balenciaga, name: 'shoes_balenciaga_socks_mdl' },
            { icon: shoes_chelseaboots, name: 'shoes_chelsea_boots_mdl' },
            { icon: shoes_sneaker, name: 'shoes_sneaker01_mdl' },
          ],
          selections: [{ name: 'default', index: 0 }],
        },
      ],
    },
  ];
};
