import { useAvatarStore } from 'services/AvatarService';
import { Color, LinearEncoding, MeshPhysicalMaterial, MeshStandardMaterial } from 'three';

function updateConfigurationGen3(clonedScene, appearance) {
  try {
    const appearanceValues = useAvatarStore.getState().computeAppearanceValues(appearance);

    const enabledObjects = [
      'avatar_head',
      'avatar_hands',
      ...Object.values(appearanceValues).map(value => value.style?.default.name),
    ];

    const enable = function(o) {
      while (o !== clonedScene) {
        o.visible = true;
        o = o.parent;
      }
    };

    clonedScene.traverse(object => {
      if (!object.isBone) {
        object.dontRenderIfInvisible = true;
        object.visible = false;
      }

      if (object.isMesh) {
        // some objects are made of multiple parts, and include a _mdl_N suffix,
        // which is removed to match the name in our config.
        let normalizedName = object.name;
        const mdlMarker = '_mdl';
        const mdlIndex = normalizedName.indexOf(mdlMarker);
        if (mdlIndex > -1) {
          normalizedName = normalizedName.substring(0, mdlIndex + mdlMarker.length);
        }

        if (!enabledObjects.includes(normalizedName)) return;

        enable(object);

        // colors

        const material = object.material.name.toLowerCase();
        // if (object.material.map) {
        //   object.material.map.encoding = LinearEncoding;
        // }

        if (material.startsWith('hair')) {
          const clonedMaterial = object.material.clone();
          clonedMaterial.color = new Color(appearanceValues.hair.color.default);
          clonedMaterial.roughness = 1;
          object.material = clonedMaterial;
        }

        if (material === 'skin_mat') {
          const { color, metalness, roughness, iridescence } = appearanceValues.skin.material.default;
          const mat = iridescence > 0 ? new MeshPhysicalMaterial() : new MeshStandardMaterial();

          mat.name = 'skin_mat';
          mat.color = new Color(color);
          mat.roughness = roughness;
          mat.metalness = metalness;

          if (mat instanceof MeshPhysicalMaterial) {
            mat.iridescence = iridescence;
          }

          object.material = mat;
        }
      }
    });
    clonedScene.visible = true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('updateConfiguration', e);
  }
}
export { updateConfigurationGen3 };
