import React, { useEffect, useRef } from 'react';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { useCameraStore } from 'services/CameraService';
import { useControlsStore } from 'services/ControlsService';
import { useContentStore } from 'services/ContentService';
import { Vector3 } from 'three';
import { usePlayerStore } from 'services/PlayerService';
import { MODE_FITTING } from 'services/PlayerService/constants';
import { useSceneStore } from 'services/SceneService';
import { useAvatarStore } from 'services/AvatarService';
import { useDebugStore } from 'storage/debug';
import FlyControls from './FlyControls';
import { useFrameAfterMount } from 'utilities/hooks';
import { useEventStore } from 'services/EventService';

function AvatarCameraController() {
  const { camera } = useThree();
  const { updateCamera, setPosition, lookAtFrom, oldOrbit } = useCameraStore.getState();
  const isFitting = usePlayerStore(state => state.mode === MODE_FITTING);
  const focusContent = useContentStore(state => state.focusContent);
  const avatarCamera = useAvatarStore(state => state.getSelectedCustomizationCategoryCamera());
  const avatarGen = useEventStore(state => state.event.avatar.gen);
  useEffect(() => {
    useCameraStore.getState().oldOrbit.enabled = true;
    useCameraStore.getState().oldOrbit.init();
    useCameraStore.setState({ mode: 'free' });
  }, []);
  useEffect(() => {
    if (focusContent) {
      const { setActiveContent } = useContentStore.getState();
      setActiveContent(focusContent.content);
    }
  }, [focusContent]);

  useEffect(() => {
    if (avatarCamera) {
      if (avatarCamera.position) {
        const pos = usePlayerStore.getState().position;
        const player = new Vector3(pos[0], pos[1], pos[2]);
        const origin = new Vector3(avatarCamera.position.x, avatarCamera.position.y, avatarCamera.position.z);
        origin.add(player);
        setPosition(origin);
        if (avatarCamera.target) {
          const target = new Vector3(avatarCamera.target.x, avatarCamera.target.y, avatarCamera.target.z);
          target.add(player);
          lookAtFrom(target, origin);
        } else {
          lookAtFrom(player, origin);
        }
      } else {
        oldOrbit.enabled = true;
      }
      if (avatarCamera.target) {
        oldOrbit.offset.set(avatarCamera.target.x, avatarCamera.target.y, avatarCamera.target.z);
      }
    } else {
      oldOrbit.enabled = true;
      oldOrbit.setFlipped(isFitting);

      if (avatarGen === 3) {
        oldOrbit.offset.set(0, isFitting ? 0.75 : 1.23, 0);
      } else {
        oldOrbit.offset.set(0, isFitting ? 1.1 : 1.5, 0);
      }
    }
  }, [avatarCamera, isFitting]);

  useFrameAfterMount((state, delta) => {
    const { collisionMesh } = useSceneStore.getState().scene;
    const { cursorPosition, cursorDown } = useControlsStore.getState();
    const { position, rotation } = usePlayerStore.getState();
    const dt = Math.min(delta, 1);
    const params = {
      camera: camera,
      delta: dt,
      playerPosition: position,
      playerRotation: rotation,
      collisionMesh: !isFitting && collisionMesh,
      cursorPosition: cursorPosition,
      cursorDown: cursorDown,
    };
    updateCamera(params);
  }, -60);
  return null;
}

extend({ FlyControls });
function FlyCameraController() {
  const { camera, gl } = useThree();
  const { domElement } = gl;
  const controls = useRef();
  useFrame((state, delta) => {
    controls.current.update(delta);
  }, -50);
  useEffect(() => {
    useCameraStore.setState({ mode: 'free' });
  }, []);
  useEffect(() => {
    if (controls.current) {
      controls.current.movementSpeed = 2.5;
      controls.current.rollSpeed = Math.PI / 12;
      controls.current.dragToLook = true;
    }
  }, [controls.current]);
  return (
    <>
      <flyControls ref={controls} args={[camera, domElement]} />
    </>
  );
}

export default function CameraController() {
  const debugStore = useDebugStore();
  const flyCamEnabled = debugStore.getFlyCamEnabled();
  return (
    <>
      {flyCamEnabled && <FlyCameraController />}
      {!flyCamEnabled && <AvatarCameraController />}
    </>
  );
}
